import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Asset44Img from './images/Asset44Img';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row className="align-items-center">
        <Col lg={8} className="mb-2">
          <h2 className="text-blue">{t('SaveMoneyFromConsultantTitle')}</h2>
          <div className="line blue-line" />
          <ol>
            <li>{t('SaveMoneyFromConsultantLi1')}</li>
            <li className="mt-2">{t('SaveMoneyFromConsultantLi2')}</li>
          </ol>
        </Col>
        <Col lg={4}>
          <Asset44Img />
        </Col>
      </Row>
    </Container>
  );
};
