import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OfferRequest from '../../components/OfferRequest';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';
import QMCloudsDokumenttiMalliImg from '../../components/images/QMCloudsDokumenttiMalliImg';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage className="container-space">
      <Container>
        <Row className="mb-5">
          <Col>
            <h1 className="text-center index-hero-h1 text-white">
              {t('IndexHeroTitle')}
            </h1>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={5}>
            <div className="hero-img-height hidden-mobile">
              <QMCloudsTabletDokumenttipohjatImg className="img-w-300" />
            </div>
            <div className="hero-card">
              <p className="text-upper bold text-white">{t('IndexSoftware')}</p>
            </div>
          </Col>
          <Col lg={2}>
            <p className="hero-box-p text-orange">+</p>
          </Col>
          <Col lg={5}>
            <div className="hero-img-height hidden-mobile">
              <QMCloudsDokumenttiMalliImg className="img-w-250" />
            </div>
            <div className="hero-card">
              <p className="text-upper bold text-white">
                {t('IndexDocuments')}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p className="text-center text-upper hero-box-p text-white">=</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center pb-4">
          <Col lg={7}>
            <div className="hero-card-2">
              <p className="text-upper bold text-white">
                {t('IndexQualityManual')}
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-4 align-items-center">
          <Col>
            <p className="text-white text-index-hero">
              Ohjelmistomme avulla varmistat EU:n whistleblower-direktiivin ja
              ilmoittajasuojelulain mukaisen anonyymin ilmoituskanavan.
            </p>
          </Col>
          <Col lg={6} className="mb-4">
            <h2 className="bold text-white">{t('IndexPrice')}</h2>
            <p className="mb-1 text-white">{t('OfferRequestButton')}:</p>
            <OfferRequest />
          </Col>
          <Col lg={6}></Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
