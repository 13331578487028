import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconText from '../../components/IconText';
import OfferRequest from '../../components/OfferRequest';
import icon1 from '../../images/icon11.svg';
import icon3 from '../../images/icon33.svg';
import icon4 from '../../images/icon44.svg';
import icon7 from '../../images/icon77.svg';
import icon8 from '../../images/icon88.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">{t('FiveStartFunctionalitiesTitle')}</h2>
          <div className="line blue-line" />
          <IconText text={t('FiveStartFunctionalities1')} src={icon1} />
          <IconText text={t('FiveStartFunctionalities2')} src={icon7} />
          <IconText text={t('FiveStartFunctionalities3')} src={icon4} />
          <IconText text={t('FiveStartFunctionalities4')} src={icon8} />
          <IconText text={t('FiveStartFunctionalities5')} src={icon3} />
        </Col>
        <Col lg={5}>
          <h3 className="text-blue">{t('AskOffer')}</h3>
          <p>{t('AskOfferP')}</p>
          <OfferRequest />
        </Col>
      </Row>
    </Container>
  );
};
