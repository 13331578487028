import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initValues = {
  email: '',
};

type ContactRequestFormBody = typeof initValues;

interface Props {
  isBackgroundBlue?: boolean;
}

export default (props: Props) => {
  const [isContactRequestSend, setIsContactRequestSend] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();
  const { isBackgroundBlue = false } = props;

  let textClassName = '';

  if (isBackgroundBlue === true) {
    textClassName = 'text-white';
  }

  const contactRequest = useCallback(
    (values: ContactRequestFormBody) => {
      if (values.email === '') {
        setIsInvalid(true);
      } else {
        let url = 'https://formspree.io/mqkbynyp';
        if (process.env.NODE_ENV !== 'production') {
          url = 'https://formspree.io/xpzrrlkb';
        }

        const body = {
          message: `Anonyymi ilmoituskanava: Otathan minuun yhteyttä mahdollisimman pian: ${values.email}`,
          _subject: `Anonyymi ilmoituskanava, yhteydenottopyyntö: ${values.email}`,
        };

        window.gtag('event', 'submit', {
          event_category: 'Contact request',
        });

        Axios.post(url, body)
          .then(() => {
            setIsContactRequestSend(true);
          })
          .catch(() => {
            setIsInvalid(true);
          });
      }
    },
    [setIsInvalid]
  );

  return (
    <Formik initialValues={initValues} onSubmit={contactRequest}>
      {isContactRequestSend ? (
        <Row>
          <Col>
            <p className="text-success font-weight-bold">
              {t('ContactRequestSend')}
            </p>
          </Col>
        </Row>
      ) : (
        <Form className="contact-request-form">
          <Row>
            <Col>
              <div>
                <p
                  className={`font-weight-bold text-blue mb-1 ${textClassName}`}
                >
                  {t('ContactRequestLeaveYourInformation')}
                </p>
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t(
                    'ContactRequestGiveYourInformationPlaceholder'
                  )}
                  name="email"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-full"
                >
                  {t('ContactRequestButton')}
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {isInvalid ? <p>{t('ContactRequestErrorMessage')}</p> : ''}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
