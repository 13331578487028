import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OfferRequest from '../../components/OfferRequest';
import Asset45Img from '../../components/images/Asset45Img';
import BackgroundImage from '../../components/images/BackgroundImage';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row className="align-items-center">
          <Col lg={6} className="mb-3">
            <h2 className="text-white">{t('DoYouWantCertificateTitle')}</h2>
            <div className="line white-line" />
            <p>{t('DoYouWantCertificateP1')}</p>
            <p>{t('DoYouWantCertificateP2')}</p>
            <p>{t('DoYouWantCertificateP3')}</p>

            <h3 className="text-white mt-3">{t('AskOffer')}</h3>
            <div className="line white-line" />

            <p>{t('AskOfferP')}</p>
            <OfferRequest />
          </Col>
          <Col lg={6}>
            <Asset45Img className="img-w-450 m-auto" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
