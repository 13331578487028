import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initValues = {
  email: '',
};

type OfferRequestFormBody = typeof initValues;

export default () => {
  const [isOfferRequestSend, setIsOfferRequestSend] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();

  const offerRequest = useCallback(
    (values: OfferRequestFormBody) => {
      if (values.email === '') {
        setIsInvalid(true);
      } else {
        let url = 'https://formspree.io/mrgvpvvw';
        if (process.env.NODE_ENV !== 'production') {
          url = 'https://formspree.io/xpzrrlkb';
        }

        const body = {
          message: `Anonyymi ilmoituskanava tarjous (QMClouds): ${values.email}`,
          _subject: `Anonyymi ilmoituskanavan tarjouspyyntö: ${values.email}`,
        };

        if (window.gtag) {
          window.gtag('event', 'submit', {
            event_category: 'Offer request',
          });
        }

        Axios.post(url, body)
          .then(() => {
            setIsOfferRequestSend(true);
          })
          .catch(() => {
            setIsInvalid(true);
          });
      }
    },
    [setIsInvalid]
  );

  return (
    <Formik initialValues={initValues} onSubmit={offerRequest}>
      {isOfferRequestSend ? (
        <Row>
          <Col>
            <p className="text-success font-weight-bold">
              {t('OfferRequestSend')}
            </p>
          </Col>
        </Row>
      ) : (
        <Form className="contact-request-form">
          <Row>
            <Col>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t(
                    'ContactRequestGiveYourInformationPlaceholder'
                  )}
                  name="email"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-full"
                >
                  {t('OfferRequestButton')}
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {isInvalid ? <p>{t('ContactRequestErrorMessage')}</p> : ''}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
