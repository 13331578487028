import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="container-space text-white">
      <Container>
        <Row>
          <Col lg={6} className="mb-4">
            <QMCloudsTabletDokumenttipohjatImg />
          </Col>
          <Col lg={6}>
            <h2 className="text-white font-weight-bold">
              {t('IndexStandardTitle')}
            </h2>
            <div className="line white-line" />
            <p>{t('IndexStandardP1')}</p>
            <p>{t('IndexStandardP2')}</p>
            <p>{t('IndexStandardP3')}</p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
