import React from 'react';

interface Props {
  src: string;
  text: string;
}

export default (props: Props) => {
  const { src, text } = props;

  return (
    <div>
      <img
        src={src}
        className="img img-w-35 mr-3 mt-1"
        style={{ display: 'inline-block', verticalAlign: 'top' }}
        alt="Icon"
      />
      <p style={{ display: 'inline-block', maxWidth: '80%' }}>{text}</p>
    </div>
  );
};
