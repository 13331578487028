import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

type SEOMeta =
  | {
      name: string;
      content: any;
      property?: undefined;
    }
  | {
      property: string;
      content: any;
      name?: undefined;
    };

interface Props {
  description: string;
  lang: string;
  meta?: SEOMeta[];
  title: string;
  pageImage: string;
  pageUrl: string;
}

const SEO = ({
  description,
  lang,
  meta = [],
  title,
  pageImage,
  pageUrl,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={[
        {
          src: 'https://kit.fontawesome.com/ab89272841.js',
          crossorigin: 'anonymous',
        },
      ]}
      link={[
        {
          rel: 'alternate',
          href: site.siteMetadata.siteUrl,
          hrefLang: 'fi',
        },
        {
          rel: 'alternate',
          href: `${site.siteMetadata.siteUrl}/sv/`,
          hrefLang: 'se',
        },
        {
          rel: 'alternate',
          href: `${site.siteMetadata.siteUrl}/en/`,
          hrefLang: 'en',
        },
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'yFukjZjMSVoDFDkdZ7BJkS7fzVirnln9wSy0ETodtHs',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: `${site.siteMetadata.siteUrl}${pageUrl}`,
        },
        {
          property: 'og:image',
          content: `${site.siteMetadata.siteUrl}${pageImage}`,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'Roadhouse Agency',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:image',
          content: `${site.siteMetadata.siteUrl}${pageImage}`,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pageImage: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
};

export default SEO;
