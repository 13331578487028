import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import blueLogo from '../images/qmc-logo-blue.svg';
import whiteLogo from '../images/qmc-logo-white.svg';
import urls from './urls';

const Header = () => {
  const [activeClass, setActiveClass] = useState('hide-header-bg');
  const { t } = useTranslation();

  const setClassToHeader = useCallback(() => {
    let localActiveClass = 'normal';
    if (window.scrollY === 0) {
      localActiveClass = 'hide-header-bg';
    }
    setActiveClass(localActiveClass);
  }, [setActiveClass]);

  useEffect(() => {
    setClassToHeader();
    window.addEventListener('scroll', setClassToHeader);
  }, [setClassToHeader]);

  return (
    <Navbar
      bg="light"
      variant="light"
      fixed="top"
      expand="xl"
      className={activeClass}
    >
      <Link to="/" className="navbar-brand">
        {activeClass === 'normal' ? (
          <img src={blueLogo} alt={t('headerAlt')} className="img" />
        ) : (
          <img src={whiteLogo} alt={t('headerAlt')} className="img" />
        )}
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Link to={urls.home} className="nav-link" activeClassName="active">
            {t('headerHome')}
          </Link>
          <Nav.Link href={urls.loginUrl} className="nav-link">
            {t('headerLogin')}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
