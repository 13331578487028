import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import appleStoreBadge from '../images/app-store-badge.svg';
import BackgroundImage from './images/BackgroundImage';
import GooglePlayImage from './images/GooglePlayImage';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage>
      <footer>
        <Container className="pt-5 text-white">
          <Row>
            <Col md={4}>
              <h2 className="text-white">{t('footerTitle')}</h2>
              <p>{t('footerPara')}</p>
            </Col>
            <Col md={4}>
              <h3 className="text-white">{t('footerContactTitle')}</h3>
              <ul>
                <li>{t('footerContactList1')}</li>
                <li>{t('footerContactList2')}</li>
                <li>{t('footerContactList3')}</li>
                <li>{t('footerContactList4')}</li>
              </ul>
            </Col>

            <Col lg={4}>
              <a href="https://play.google.com/store/apps/details?id=com.QMClouds&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <GooglePlayImage />
              </a>
              <a href="https://apps.apple.com/fi/app/qmclouds/id1414538728">
                <img
                  alt="Apple Store logo"
                  src={appleStoreBadge}
                  className="img img-w-200"
                />
              </a>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <p className="text-center">
                ©{new Date().getFullYear()} QMClouds Oy
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </BackgroundImage>
  );
};

export default Footer;
