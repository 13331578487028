import React from 'react';
import SaveMoney from '../components/SaveMoney';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import ChooseStandardQualitySystem from '../pages-partials/index/ChooseStandardQualitySystem';
import Contact from '../pages-partials/index/Contact';
import Customers from '../pages-partials/index/Customers';
import DoYouWantCertificate from '../pages-partials/index/DoYouWantCertificate';
import FiveStarFunctionalities from '../pages-partials/index/FiveStarFunctionalities';
import Hero from '../pages-partials/index/Hero';
import HowToUseApp from '../pages-partials/index/HowToUseApp';

declare global {
  interface Window {
    gtag: any;
  }
}

const IndexPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('indexSeoTitle')}
      description={t('indexSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/"
      lang={locale}
    />
    <Hero />
    <SaveMoney />
    <ChooseStandardQualitySystem />
    <FiveStarFunctionalities />
    <HowToUseApp />
    <Customers />
    <DoYouWantCertificate />
    <Contact />
  </Layout>
);
export default withI18next({ ns: 'common' })(IndexPage);
